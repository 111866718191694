import { toast } from "react-toastify";

export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    let errorMessage;
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            errorMessage = "User denied the request for geolocation.";
                            break;
                        case error.POSITION_UNAVAILABLE:
                            errorMessage = "Location information is unavailable.";
                            break;
                        case error.TIMEOUT:
                            errorMessage = "The request to get user location timed out.";
                            break;
                        default:
                            errorMessage = `An unknown error occurred: ${error.message}`;
                    }
                    toast.error(errorMessage);
                    reject(errorMessage);
                }
            );
        } else {
            const errorMessage = "Geolocation is not supported by this browser.";
            toast.error(errorMessage);
            reject(errorMessage);
        }
    });
};

export const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    // Function to get ordinal suffix for the day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // For 11th, 12th, 13th, etc.
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

    return `${dayWithSuffix} ${month} ${year}`;
}


export const headerDate = () => {
    const currentDate = new Date()
    const dayOfWeek = currentDate.toLocaleString('en', { weekday: 'short' });
    const dayOfMonth = currentDate.getDate();
    const month = currentDate.toLocaleString('en', { month: 'short' });
    return `${dayOfWeek}, ${dayOfMonth} ${month}`;
}

export const headerTime = () => {
    const currentDate = new Date()
    return currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
}

export const getFormatted = (date) => {
    const currentDate = new Date(`${date}`);
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${year}-${month}-${day}`;
}

export const getFormattedDisplay = (date) => {
    const currentDate = new Date(`${date}`);
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();

    return `${day}-${month}-${year}`;
}

export const getFormattedString = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    // Function to get ordinal suffix for the day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; // For 11th, 12th, 13th, etc.
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

    return `${dayWithSuffix} ${month} ${year}`;
}
