import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { FaCheckCircle } from 'react-icons/fa';

//SVG
import { APIENDPOINT } from '../global/Const';
import { setData } from '../redux/actionTypes/rootAction';
import { postData, postDataFormData } from '../global/Api';
import Header from '../components/common/Header';
import Spinner from '../components/common/Spinner';
import '../css/main.css';

function Buyerdetail() {
    const { counter_id, sightseen_id, product_id } = useParams();
    const [modal, setModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [otp, setOTP] = useState('');
    const [stateList, setState] = useState([]);
    const [cityList, setCity] = useState([]);
    const [validationType, setvalidationType] = useState('');
    const [buyerDetails, setBuyerDetail] = useState({
        name: '',
        email: '',
        mobile: '',
        id_proof: '',
        id_proof_no: '',
        verification_token: '',
        company: '',
        company_email: '',
        company_mobile: '',
        state_id: '',
        city_id: '',
        pincode: '',
        address: '',
        gst_no: '',
        is_gst: false,
        is_mobile: false,
        is_email: false,
        is_mobile_check: false,
        is_email_check: false,
    });
    const [errors, setErrors] = useState({});
    const state = useSelector((state) => state);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (state?.buyer_details) {
            setBuyerDetail(state?.buyer_details)
        }
        if (state?.buyer_details?.state_id) {
            citybyid(state?.buyer_details?.state_id)
        }
    }, [state?.buyer_details])

    useEffect(() => {
        states()
    }, [])

    const states = async () => {
        const formData = ""
        setisLoading(true)
        const responseApi = await postDataFormData(APIENDPOINT.STATES, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            setState(responseApi.data)
            setisLoading(false)
        } else if (responseApi.message) {
            toast.error(responseApi.message)
            setisLoading(false)
            return
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
            return
        }
    }
    const citybyid = async (id) => {
        const formData = {
            "state_id": id
        }
        setisLoading(true)
        const responseApi = await postData(APIENDPOINT.CITY, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            setCity(responseApi.data)
            setisLoading(false)
        } else if (responseApi.message) {
            toast.error(responseApi.message)
            setisLoading(false)
            return
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
            return
        }
    }
    const notSelected = (value) => {
        const data = {
            ...state,
            buyer_details: {
                ...buyerDetails,
                company: '',
                state_id: '',
                city_id: '',
                pincode: '',
                address: '',
                gst_no: '',
                is_gst: value,
            }
        }
        dispatch(setData(data))
    }
    const sendOTP = async (type) => {
        let formData = {};
        let error = {};
        const mobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (type === "Mobile") {
            if (!buyerDetails.mobile || !mobileRegex.test(buyerDetails.mobile)) {
                error.mobile = 'Please enter 10 digit mobile';
                setErrors(error);
                return;
            }
            formData = {
                mobile: buyerDetails.mobile,
                sightseeing_id: sightseen_id,
                verification_token: buyerDetails.verification_token
            };
        }

        if (type === "Email") {
            if (!buyerDetails.email || !emailRegex.test(buyerDetails.email)) {
                error.email = 'Please enter valid email id';
                setErrors(error);
                return;
            }
            formData = {
                email: buyerDetails.email,
                sightseeing_id: sightseen_id,
                verification_token: buyerDetails.verification_token
            };
        }
        setisLoading(true)
        const responseApi = await postData(APIENDPOINT.SENDOTP, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            toggle();
            setvalidationType(type);
            setBuyerDetail({
                ...buyerDetails,
                verification_token: responseApi.data.verification_token
            });
            setisLoading(false)
            setOTP('')
            setErrors({})
        } else if (responseApi.message) {
            toast.error(responseApi.message)
            setisLoading(false)
            setOTP('')
            setErrors({})
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
            setOTP('')
            setErrors({})
        }
    };
    const verifyOTP = async (type) => {
        const formData = {
            "verification_token": buyerDetails.verification_token,
            "otp": otp
        };
        let error = {};

        setisLoading(true)
        const responseApi = await postData(APIENDPOINT.VERIFYOTP, formData);
        if (responseApi.response_code === 200 && responseApi.status === true) {
            if (validationType === "Mobile") {
                setBuyerDetail({ ...buyerDetails, is_mobile_check: !buyerDetails.is_mobile_check })
                setBuyerDetail({ ...buyerDetails, is_mobile: !buyerDetails.is_mobile })
                toggle()
                setisLoading(false)
            }
            if (validationType === "Email") {
                setBuyerDetail({ ...buyerDetails, is_email_check: !buyerDetails.is_email_check })
                setBuyerDetail({ ...buyerDetails, is_email: !buyerDetails.is_email })
                toggle()
                setisLoading(false)
            }
        } else if (responseApi.message) {
            error.otp = responseApi.message
            setErrors(error)
            // toast.error(responseApi.message)
            setisLoading(false)
        } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
        }
    };
    const validateForm = () => {
        let error = {};

        if (buyerDetails.is_email || buyerDetails.is_mobile) {
            if (buyerDetails.is_gst) {
                if (!buyerDetails.company) {
                    error.company_name = "Please enter company name"
                }
                if (!buyerDetails.company_email) {
                    error.company_email = "Please enter company email"
                }
                if (!buyerDetails.company_mobile) {
                    error.company_mobile = "Please enter company mobile"
                }
                if (!buyerDetails.state_id) {
                    error.state = "Please select state"
                }
                if (!buyerDetails.city_id) {
                    error.city = "Please select city"
                }
                if (!buyerDetails.pincode || buyerDetails.pincode.length !== 6) {
                    error.pincode = "Please enter 6 digit pincode"
                }
                if (!buyerDetails.address) {
                    error.address = "Please enter valid address"
                }
                if (!buyerDetails.gst_no || buyerDetails.gst_no.length !== 15) {
                    error.gst_no = "Please enter 15 digit gst no"
                }
            } else {
                setErrors({})
            }
        } else {
            error.mobile = 'Please verify mobile';
            error.email = 'Please verify email id';
        }

        setErrors(error);

        if (Object.keys(error).length === 0) {
            const data = {
                ...state,
                // is_subscribed: buyerDetails.is_email,
                is_subscribed: buyerDetails.email ? true : false,
                buyer_details: buyerDetails
            };
            dispatch(setData(data));
            navigate(`/counter/${counter_id}/${sightseen_id}/${product_id}/review-order`)
        }
    };

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={3} counterId={counter_id} />
            <Spinner loading={isLoading} />
            <div className="BuyerDetailContainer">
                <div className="container py-3">
                    <Modal
                        isOpen={modal}
                        toggle={toggle}
                        backdrop={'static'}
                        keyboard={false}
                    >
                        <ModalHeader toggle={toggle}>Verify OTP</ModalHeader>
                        <ModalBody>
                            <div className='col-12'>
                                <input
                                    maxLength={6}
                                    type="text"
                                    placeholder="XXXXXX"
                                    className="col form-control p-2 bg-custom-input"
                                    value={otp}
                                    onChange={(e) => {
                                        const enteredValue = e.target.value;
                                        const numericValue = enteredValue.replace(/\D/g, '');
                                        setOTP(numericValue);
                                    }}
                                    style={{ letterSpacing: 10, fontSize: 20, textAlign: 'center' }}
                                />
                                <p className='pt-1 text-center text-danger m-0'>{errors.otp}</p>
                            </div>
                        </ModalBody>


                        <ModalFooter>
                            <Button className='bg-custom-color-secondary' style={{ borderColor: "#AA4332" }} onClick={() => verifyOTP()}>
                                Submit
                            </Button>
                            {''}
                            <Button color="secondary" onClick={toggle}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>

                    <div>
                        <h3 className="text-center m-0 d-flex justify-content-center">Buyer Detail
                            {/* <span className='text-color-primary fs-14 ps-1 align-content-center'>({nationality})</span> */}
                        </h3>
                        {/* <h5 className="text-center m-0 text-color-primary">Indian</h5> */}
                    </div>
                    <div className="row mt-3 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'>Name</span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <input
                                type="text"
                                className="form-control p-2 bg-custom-input"
                                placeholder="Full Name"
                                value={buyerDetails.name}
                                onChange={(e) => {
                                    const regex = /^[A-Za-z\s]*$/;
                                    if (regex.test(e.target.value) || e.target.value === '') {
                                        setBuyerDetail({ ...buyerDetails, name: e.target.value });
                                    }
                                }}
                                pattern="[A-Za-z]*"
                            />
                        </div>
                    </div>

                    <div className="row mt-4 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'>Email <span className='text-danger'>*</span></span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control p-2 bg-custom-input"
                                    placeholder="Email Address"
                                    value={buyerDetails.email}
                                    onChange={(e) => {
                                        const trimmedValue = e.target.value.trim();
                                        if (buyerDetails.is_email_check !== trimmedValue) {
                                            setBuyerDetail({
                                                ...buyerDetails,
                                                email: trimmedValue,
                                                is_email: false
                                            });
                                        } else {
                                            setBuyerDetail({
                                                ...buyerDetails,
                                                email: trimmedValue
                                            });
                                        }
                                    }}
                                />
                                <button type="button" className="btn bg-custom-input text-color-primary text-decoration-none border-none" onClick={() => sendOTP("Email")}>
                                    {buyerDetails.is_email ? <FaCheckCircle className="ml-2 text-success" /> : "Verify"}
                                </button>
                            </div>
                        </div>
                        <p className='py-1 error-custom text-danger m-0'>{errors.email}</p>
                    </div>
                    <div className="row mt-4 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'> <span className='text-danger'></span></span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <div className="separator">OR</div>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'>Mobile <span className='text-danger'>*</span></span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <div className="input-group">
                                <span className="input-group-text rounded">+91</span>
                                <input
                                    type="text"
                                    maxLength={10}
                                    className="form-control p-2 bg-custom-input"
                                    placeholder="Mobile Number"
                                    value={buyerDetails.mobile}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        const filteredInput = input.replace(/\D/g, '');
                                        setBuyerDetail({
                                            ...buyerDetails,
                                            mobile: filteredInput
                                        });
                                    }}
                                />
                                <button type="button" className="btn bg-custom-input text-color-primary text-decoration-none border-none" onClick={() => sendOTP("Mobile")}>
                                    {buyerDetails.is_mobile ? <FaCheckCircle className="ml-2 text-success" /> : "Verify"}
                                </button>
                            </div>
                        </div>
                        <p className='py-1 error-custom text-danger m-0'>{errors.mobile}</p>
                    </div>
                    {/* <div className="row mt-4 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'>Select ID Proof </span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <div className="input-group">
                            <select
                                className="form-control p-2 bg-custom-input"
                                id="exampleFormControlSelect1"
                                value={buyerDetails.id_proof}
                                onChange={(e) => setBuyerDetail({ ...buyerDetails, id_proof: e.target.value })}
                            >
                                <option value={""}>Select</option>
                                <option value={'Aadhar Card'}>Aadhar Card</option>
                                <option value={'Driving License'}>Driving License</option>
                                <option value={'Pan Card'}>Pan Card</option>
                                <option value={'Passport'}>Passport</option>
                                <option value={'Voter ID'}>Voter ID</option>
                            </select>
                            <div className='bg-custom-input align-content-center'>
                                    <BsChevronDown className='mx-2' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 justify-content-center px-3">
                        <div className="col-3 p-0 text-left align-self-center fw-bold">
                            <span className='fs-13'>ID Proof No</span>
                        </div>
                        <div className="col-9 text-center p-0">
                            <input type="text" className="form-control p-2 bg-custom-input" placeholder={buyerDetails.id_proof + " " + 'Number'} value={buyerDetails.id_proof_no} onChange={(e) => setBuyerDetail({ ...buyerDetails, id_proof_no: e.target.value })} />
                        </div>
                    </div> */}
                    <div className="row mt-4 justify-content-center px-2">
                        <div className="col-12 d-flex">
                            <input
                                checked={buyerDetails.is_gst}
                                type="checkbox"
                                id="showFields"
                                onChange={(e) => {
                                    if (buyerDetails.is_gst === true && e.target.checked === false) {
                                        // setBuyerDetail({ ...buyerDetails, is_gst: e.target.checked });
                                        notSelected(e.target.checked);
                                    } else {
                                        console.log('data')
                                        setBuyerDetail({ ...buyerDetails, is_gst: e.target.checked, company_email: buyerDetails.email, company_mobile: buyerDetails.mobile });
                                    }
                                }}
                            />
                            <label htmlFor="showFields" className="ps-3 fs-13">Need GST No Benefits (Optional)</label>
                        </div>
                    </div>
                    {buyerDetails.is_gst && (
                        <>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className="fs-13">Company Name <span className="text-danger">*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="Company Name"
                                        value={buyerDetails.company}
                                        onChange={(e) => setBuyerDetail({ ...buyerDetails, company: e.target.value })}
                                    />
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.company_name}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className="fs-13">Email <span className="text-danger">*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="Email Address"
                                        value={buyerDetails.company_email}
                                        onChange={(e) => {
                                            const trimmedValue = e.target.value.trim();
                                            setBuyerDetail({ ...buyerDetails, company_email: trimmedValue })
                                        }}
                                    />
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.company_email}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className="fs-13">Mobile <span className="text-danger">*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="Mobile Number"
                                        maxLength={10}
                                        value={buyerDetails.company_mobile}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const filteredInput = input.replace(/\D/g, '');
                                            setBuyerDetail({ ...buyerDetails, company_mobile: filteredInput })
                                        }}
                                    />
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.company_mobile}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>State <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    {/* <div className='input-group'> */}
                                    <select
                                        className="form-control p-2 bg-custom-input"
                                        id="exampleFormControlSelect1"
                                        value={buyerDetails.state_id}
                                        onChange={(e) => {
                                            citybyid(e.target.value)
                                            setBuyerDetail({ ...buyerDetails, state_id: e.target.value })
                                        }}
                                    >
                                        <option value="">Select</option>
                                        {stateList.map((item) => {
                                            return (
                                                <option value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <div className='bg-custom-input align-content-center'>
                                            <BsChevronDown className='mx-2' />
                                        </div>
                                    </div> */}
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.state}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>City <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    {/* <div className='input-group'> */}
                                    <select
                                        key={cityList}
                                        className="form-control p-2 bg-custom-input"
                                        id="exampleFormControlSelect1"
                                        value={buyerDetails.city_id}
                                        onChange={(e) => setBuyerDetail({ ...buyerDetails, city_id: e.target.value })}
                                    >
                                        <option value="">Select</option>
                                        {cityList?.map((item) => {
                                            return (
                                                <option value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                    {/* <div className='bg-custom-input align-content-center'>
                                            <BsChevronDown className='mx-2' />
                                        </div>
                                    </div> */}
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.city}</p>
                            </div>

                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>Pincode <span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        maxLength={6}
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="123456"
                                        value={buyerDetails.pincode}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const numericInput = input.replace(/\D/g, ''); // Remove non-digit characters
                                            setBuyerDetail({ ...buyerDetails, pincode: numericInput });
                                        }}
                                    />
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.pincode}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className='fs-13'>Address<span className='text-danger'>*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="Street Address"
                                        value={buyerDetails.address}
                                        onChange={(e) => setBuyerDetail({ ...buyerDetails, address: e.target.value })}
                                    />
                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.address}</p>
                            </div>
                            <div className="row mt-4 justify-content-center px-3">
                                <div className="col-3 p-0 text-left align-self-center fw-bold">
                                    <span className="fs-13">GST No (i)<span className="text-danger">*</span></span>
                                </div>
                                <div className="col-9 text-center p-0">
                                    <input
                                        maxLength={15}
                                        type="text"
                                        className="form-control p-2 bg-custom-input"
                                        placeholder="123456879123"
                                        value={buyerDetails.gst_no}
                                        onChange={(e) => {
                                            const input = e.target.value;
                                            const alphanumericInput = input.replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters
                                            setBuyerDetail({ ...buyerDetails, gst_no: alphanumericInput });
                                        }}
                                    />

                                </div>
                                <p className='py-1 error-custom text-danger m-0'>{errors.gst_no}</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                    {/* <div onClick={handleonClick}> */}
                    <div onClick={validateForm}>
                        <span className='fs-6'>NEXT</span>
                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer ms-3" />
                    </div>
                </div>
            </footer>
        </div >
    );
}

export default Buyerdetail;
