// import React from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { FaChevronRight } from "react-icons/fa6";
// import { useSelector } from 'react-redux';

// //SVG
// import Header from '../components/common/Header';
// import '../css/main.css';

// function Support() {
//     const { counter_id } = useParams();
//     const state = useSelector((state) => state);
//     const navigate = useNavigate()
//     return (
//         <div className="App">
//             <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} />
//             <div className="SupportContent">
//                 <div className="container">
//                     <h3 className="text-center py-3 m-0">Support & Help</h3>
//                     <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/how-to-buy`)}>
//                         <div className="text-light pe-3">
//                             <span className='text-dark'>1.</span>
//                         </div>
//                         <div className="list-label text-dark">How to buy ticket</div>
//                         {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
//                         <FaChevronRight className='custom-icon-main-list text-dark' />
//                     </div>
//                     <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/payment-failed`)}>
//                         <div className="text-light pe-3">
//                             <span className='text-dark'>2.</span>
//                         </div>
//                         <div className="list-label text-dark">Payment failed</div>
//                         {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
//                         <FaChevronRight className='custom-icon-main-list text-dark' />
//                     </div>
//                     <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/purchese-additional-ticket`)}>
//                         <div className="text-light pe-3">
//                             <span className='text-dark'>3.</span>
//                         </div>
//                         <div className="list-label text-dark">Purchase additional ticket</div>
//                         {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
//                         <FaChevronRight className='custom-icon-main-list text-dark' />
//                     </div>
//                     <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/update-contact-details`)}>
//                         <div className="text-light pe-3">
//                             <span className='text-dark'>4.</span>
//                         </div>
//                         <div className="list-label text-dark">Update contact details</div>
//                         {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
//                         <FaChevronRight className='custom-icon-main-list text-dark' />
//                     </div>
//                 </div>
//             </div>
//             <footer className="Footer">
//                 <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
//                 <div className="FooterCompoenent">
//                     <div onClick={() => navigate(-1)}>
//                         <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
//                         <span className='fs-6'>PREVIOUS</span>
//                     </div>
//                 </div>
//             </footer>

//         </div>
//     );
// }

// export default Support;
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaChevronRight } from "react-icons/fa6";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";

//SVG
import Header from '../components/common/Header';
import '../css/main.css';
import { APIENDPOINT } from '../global/Const';
import { getData } from '../global/Api';
import Spinner from '../components/common/Spinner';

function Support() {
    const { counter_id } = useParams();
    const [isLoading, setisLoading] = useState(true);
    const state = useSelector((state) => state);
    const [apiResponse, setapiResponse] = useState()
    const navigate = useNavigate()

    const getSupportList = async () => {
        try {
            const responseApi = await getData(APIENDPOINT.GETALLSUPPORTLIST);
            if (responseApi.response_code === 200 && responseApi.status === true) {
                setisLoading(false);
                setapiResponse(responseApi)
            } else if (responseApi.message) {
                toast.error(responseApi.message);
                setisLoading(false);
            } else {
                toast.error("Something Went Wrong !!");
                setisLoading(false);
            }
        } catch (error) {
            toast.error("Something Went Wrong !!");
            setisLoading(false);
        } finally {
            setisLoading(false);
        }
    };


    useEffect(() => {
        getSupportList();
    }, []);

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} counterId={counter_id} style={2} />
            <Spinner loading={isLoading} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Help & Support</h3>
                    <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/payment-failed`)}>
                        <div className="text-light pe-3">
                            <span className='text-dark'>1.</span>
                        </div>
                        <div className="list-label text-dark">How do I access or download my ticket if there's an issue?</div>
                        {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                        <FaChevronRight className='custom-icon-main-list text-dark' />
                    </div>
                    {apiResponse?.data?.map((item, index) => {
                        return (
                            <div className="list-main mb-3" onClick={() => navigate(`/counter/${counter_id}/support-help/${item.question}`)}>
                                <div className="text-light pe-3">
                                    <span className='text-dark'>{index + 2}.</span>
                                </div>
                                <div className="list-label text-dark">{item.question}</div>
                                {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                                <FaChevronRight className='custom-icon-main-list text-dark' />
                            </div>

                        )
                    })
                    }
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default Support;
