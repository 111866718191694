import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoHomeOutline } from "react-icons/io5";
import { TfiMenu } from "react-icons/tfi";
import { headerDate, headerTime } from "../../global/Function";
import '../../css/main.css';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";

function Header({ mainlogo, sublogo, style, counterId, screen }) {
    const navigate = useNavigate()
    const [Time, setTime] = useState()
    const [menu, setMenu] = useState(false)
    const toggleMenu = () => { setMenu(!menu) }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(headerTime())
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <React.Fragment>
            <div>
                <Offcanvas
                    style={{ width: "70%", backgroundColor: '#FAF7F2' }}
                    isOpen={menu}
                    toggle={toggleMenu}
                >
                    <OffcanvasHeader toggle={toggleMenu}>

                    </OffcanvasHeader>
                    <OffcanvasBody className="p-0">
                        <div className="SupportContentMenu">
                            <div className="container px-4">
                                <div>
                                    {/* <p className="policy-title h5">Consumer Policy</p> */}
                                    <div className="my-3" onClick={() => navigate(`/counter/${counterId}`)}>
                                        <div className="text-light pe-2">
                                            {/* <span className='text-dark'>1.</span> */}
                                        </div>
                                        <div className="list-label text-dark">Home</div>
                                        {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                                        {/* <FaChevronRight className='custom-icon-main-list text-dark' /> */}
                                    </div>
                                    <div className="my-3" onClick={() => navigate(`/counter/${counterId}/support-help`)}>
                                        <div className="text-light pe-2">
                                            {/* <span className='text-dark'>1.</span> */}
                                        </div>
                                        <div className="list-label text-dark">Help & Support</div>
                                        {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                                        {/* <FaChevronRight className='custom-icon-main-list text-dark' /> */}
                                    </div>
                                    {/* <div className="mb-3" onClick={() => navigate(`/counter/${counterId}/termscondition`)}>
                                        <div className="text-light pe-2">
                                            <span className='text-dark'>1.</span>
                                        </div>
                                        <div className="list-label text-dark">Terms & Conditions</div>
                                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" />
                                        <FaChevronRight className='custom-icon-main-list text-dark' />
                                    </div> */}
                                    {/* <div className="mb-3" onClick={() => navigate(`/counter/${counterId}/disclaimer`)}>
                                        <div className="text-light pe-2">
                                            <span className='text-dark'>2.</span>
                                        </div>
                                        <div className="list-label text-dark">Disclaimer</div>
                                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" />
                                        <FaChevronRight className='custom-icon-main-list text-dark' />
                                    </div> */}
                                    {/* <div className="mb-3" onClick={() => navigate(`/counter/${counterId}/privacypolicy`)}>
                                        <div className="text-light pe-2">
                                            <span className='text-dark'>3.</span>
                                        </div>
                                        <div className="list-label text-dark">Privacy Policy</div>
                                        <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" />
                                        <FaChevronRight className='custom-icon-main-list text-dark' />
                                    </div> */}
                                    <div className="mb-3" onClick={() => navigate(`/counter/${counterId}/refundpolicy`)}>
                                        {/* <div className="text-light pe-2">
                                            <span className='text-dark'>4.</span>
                                        </div> */}
                                        <div className="list-label text-dark">Refund & Cancellation Policy</div>
                                        {/* <img src={require('../assets/icon/right_arrow.png')} className="custom-icon-main-footer" /> */}
                                        {/* <FaChevronRight className='custom-icon-main-list text-dark' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
            {style == 2 ?
                <header className="Header d-flex justify-content-between align-items-center">
                    {screen != "404" &&
                        <div style={{ width: '33.33%' }}>
                            {/* <IoMdHelpCircleOutline onClick={() => navigate(`/counter/${counterId}/support-help`)} className="Icon" /> */}
                            <TfiMenu onClick={toggleMenu} className="Icon" width={10} height={10} />
                        </div>
                    }
                    <div style={{ width: screen != "404" ? "33.33%" : '57.5%', textAlign: screen != "404" ? "center" : 'right' }}>
                        {(sublogo || mainlogo) &&
                            <img src={sublogo ? sublogo : mainlogo} alt="header-logo" className="Logo" />
                        }
                    </div>
                    <div style={{ width: screen != "404" ? "33.33%" : 'auto', textAlign: 'right' }}>
                        <div className="text-center float-end">
                            <p className={screen != "404" ? "m-0 fw-bold mt-1" : "m-0 fw-bold mt-1 text-center"} style={{ fontSize: 16 }}>{Time}</p>
                            <p className="m-0" style={{ fontSize: 11, position: 'relative', top: -5 }}>{headerDate()}</p>
                        </div>
                    </div>
                </header> :
                <header className="Header d-flex justify-content-between align-items-center">
                    <div style={{ width: '33.33%' }}>
                        {/* <IoHomeOutline onClick={() => navigate(`/counter/${counterId}`)} className="Icon" /> */}
                        <TfiMenu onClick={toggleMenu} className="Icon" width={10} height={10} />
                    </div>
                    <div style={{ width: '33.33%', textAlign: 'center' }}>
                        {(sublogo || mainlogo) &&
                            <img src={sublogo ? sublogo : mainlogo} alt="header-logo" className="Logo" />
                        }
                    </div>
                    <div style={{ width: '33.33%', textAlign: 'right' }}>
                        <div className="text-center float-end">
                            <p className="m-0 fw-bold mt-1" style={{ fontSize: 16 }}>{headerTime()}</p>
                            <p className="m-0" style={{ fontSize: 11, position: 'relative', top: -5 }}>{headerDate()}</p>
                        </div>
                    </div>
                </header>
            }
        </React.Fragment >
    );
}

export default Header;
