// import { createStore } from "redux";
// import { rootReducer } from "./redux/reducers/rootReducer";


// const store = createStore(rootReducer);

// export default store;

import { createStore } from "redux";
import { rootReducer } from "./redux/reducers/rootReducer";

// Load initial state from local storage if available
const persistedState = localStorage.getItem('reduxState')
    ? JSON.parse(localStorage.getItem('reduxState'))
    : {};

const store = createStore(rootReducer, persistedState);

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;
