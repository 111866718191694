import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';

//PRIVATE ROUTE
import PrivateRoute from './components/auth/PrivateRoute';
//REDUX STORE 
import store from './store';
//CSS
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import Error404 from './components/common/Error404';
import Place from './Pages/Place';
import Dashboard from './Pages/Dashboard';
import Booking from './Pages/Booking';
import Buyerdetail from './Pages/Buyerdetail';
import Revieworder from './Pages/Revieworder';
import Confirmbooking from './Pages/Confirmbooking';
import Support from './Pages/Support';
import QuestionAnswer from './Pages/subpage/QuestionAnswer';
import PaymentFail from './Pages/subpage/PaymentFail';
import VerifiedOTP from './Pages/subpage/VerifiedOTP';
import AdminRecive from './Pages/subpage/AdminRecive';
import RecivedGateway from './Pages/subpage/RecivedGateway';
import AdminNotRecive from './Pages/subpage/AdminNotRecive';
import Terms from './Pages/subpage/Terms';
import Disclaimer from './Pages/subpage/Disclaimer';
import PrivacyPolicy from './Pages/subpage/PrivacyPolicy';
import RefundPolicy from './Pages/subpage/RefundPolicy';

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Routes>
            <Route exact path={process.env.PUBLIC_URL + '/'} element={<PrivateRoute />}>
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id'} element={<Dashboard />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/:sightseen_id'} element={<Place />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/:sightseen_id/:product_id'} element={<Booking />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/:sightseen_id/:product_id/buyer-detail'} element={<Buyerdetail />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/:sightseen_id/:product_id/review-order'} element={<Revieworder />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/:sightseen_id/:product_id/confirm-booking/:order_id'} element={<Confirmbooking />} />
              <Route exact path={process.env.PUBLIC_URL + '/view-ticket/:order_id'} element={<Confirmbooking />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help'} element={<Support />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/termscondition'} element={<Terms />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/disclaimer'} element={<Disclaimer />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/privacypolicy'} element={<PrivacyPolicy />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/refundpolicy'} element={<RefundPolicy />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/:question'} element={<QuestionAnswer />} />
              <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/payment-failed'} element={<PaymentFail />} />
              {/* <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/payment-failed/verified-otp'} element={<VerifiedOTP />} /> */}
              {/* <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/payment-failed/verified-otp/1'} element={<AdminRecive />} /> */}
              {/* <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/payment-failed/verified-otp/2'} element={<RecivedGateway />} /> */}
              {/* <Route exact path={process.env.PUBLIC_URL + '/counter/:counter_id/support-help/payment-failed/verified-otp/3'} element={<AdminNotRecive />} /> */}
            </Route>
            <Route exact path={process.env.PUBLIC_URL + '/'} element={<Dashboard />} />
            <Route path={process.env.PUBLIC_URL + '*'} element={<Error404 />} />
          </Routes>
        </Fragment>
      </Router>
    </Provider>
  );
}