import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ onClick, text, width, color, href }) => {
    return (
        <Link to={href}>
            <button className="custom-button" style={{ width: `${width}%`, backgroundColor: color }} onClick={onClick}>
                {text}
            </button>
        </Link>
    );
};

export default Button;
