import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//SVG
import Header from '../../components/common/Header';
import '../../css/main.css';

function RefundPolicy() {
    const state = useSelector((state) => state);
    const navigate = useNavigate()
    const { counter_id } = useParams();

    const estateofmbsm = `<h2 style="color: rgb(0, 0, 0); font-size: 14pt;">
    Refund and transfer
</h2>
<p style="color: rgb(0, 0, 0); font-size: 11pt;">
    Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.
</p>

`
    const hrhhotels = `<h2 style="font-size: 14pt; color: rgb(0, 0, 0);">
    Refund and transfer
</h2>
<p style="font-size: 11pt; color: rgb(0, 0, 0);">
    Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.
</p>

`
    const citypalacemuseum = `<h2>
    
        <span style="font-size: 14pt; color: rgb(0, 0, 0);">Refund and transfer</span>
    
</h2>
<p>
    <span style="font-size: 11pt; color: rgb(0, 0, 0);">Ticket sales are final and binding. This Ticket is non-transferable and non-refundable.</span>
</p>

`

    return (
        <div className="App">
            <Header sublogo={state.sub_logo} mainlogo={state.main_logo} style={2} counterId={counter_id} />
            <div className="SupportContent">
                <div className="container">
                    <h3 className="text-center py-3 m-0">Refund & Cancellation Policy</h3>
                    <div dangerouslySetInnerHTML={{ __html: estateofmbsm }} />
                    {/* {window.location.href.includes('eticket.estateofmbsm.org') &&
                        <></>
                    }
                    {window.location.href.includes('eticket.hrhhotels.com') &&
                        <div dangerouslySetInnerHTML={{ __html: hrhhotels }} />
                    }
                    {window.location.href.includes('eticket.citypalacemuseum.org') &&
                        <div dangerouslySetInnerHTML={{ __html: citypalacemuseum }} />
                    } */}
                </div>
            </div>
            <footer className="Footer">
                <div style={{ height: 25, width: "100%", backgroundColor: "#FAF7F2", borderBottomLeftRadius: 25, borderBottomRightRadius: 25 }}></div>
                <div className="FooterCompoenent">
                    <div onClick={() => navigate(-1)}>
                        <img src={require('../../assets/icon/left_arrow.png')} className="custom-icon-main-footer me-3" />
                        <span className='fs-6'>PREVIOUS</span>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default RefundPolicy;
