import { CLEAR_DATA, RESET_TO_BLANK, SET_DATA } from "../actionTypes/action";

const initialState = {
    main_logo: '',
    sub_logo: '',
    counter_slug: "",
    user_lat: "",
    user_long: "",
    sightseeing_id: "",
    sightseeing_name: "",
    product_id: "",
    product_name: "",
    product_validity: "",
    date: "",
    sale_head: [],
    buyer_details: {
        name: "",
        email: "",
        mobile: "",
        verification_token: "",
        company: "",
        address: "",
        state_id: "",
        city_id: "",
        pincode: "",
        gst_no: "",
        gst_email: "",
        id_proof: "",
        id_proof_no: "",
        is_gst: false,
        is_mobile: false,
        is_email: false,
        is_mobile_check: false,
        is_email_check: false,
    },
    terms_conditions: "",
    disclaimer: "",
    privacy_policy: "",
    refund_cancellation_policy: "",
    terms_condition_accepted: false,
    is_subscribed: false
};


export const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA:
            return {
                ...state,
                ...action.payload
            };
        case CLEAR_DATA:
            return initialState;
        case RESET_TO_BLANK:
            return {
                ...initialState,
                main_logo: state.main_logo,
                sub_logo: state.sub_logo
            };
        default:
            return state;
    }
};
